import { getLanguageCode } from 'src/utils/language';

/**
 * 코드에서 사용중인 국가코드와 젠데스크에서 사용중인 국가코드 간 차이 조정
 */
export const getZendeskLanguage = () => {
  const languageCode = getLanguageCode().replace('_', '-').toLowerCase();
  if (languageCode === 'zh-rtw') {
    return 'zh-tw';
  }
  if (languageCode === 'en') {
    return 'en-us';
  }
  if (languageCode === 'pt') {
    return 'pt-br';
  }
  return languageCode;
};
