import { Article } from 'node-zendesk/dist/types/clients/helpcenter/articles';
import { Section } from 'node-zendesk/dist/types/clients/helpcenter/sections';
import { getBlogPathWithLanguage } from './language';
import QueryString from 'qs';

export function getFirstImage(htmlString: string) {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlString, 'text/html');
  const imgElement = htmlDoc.querySelector('img');
  return imgElement ? imgElement.src : '/images/blog/blog_thumb.png';
}

export function htmlToText(htmlString: string): string {
  const strippedString = htmlString.replace(/<br>/g, '').replace(/(\n)/g, '');
  return strippedString.replace(/<[^>]+>/g, '');
}

/**
 * URL 인코딩, 디코딩에도 변환되지 않도록 게시글 제목에 포함된 특수문자, 공백문자 등 모두 삭제
 */
export const normalizeStringForURL = (title: string) => {
  return title.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '');
};

interface BlogPathParams {
  section: Section;
  article: Article;
  queryParam?: Record<string, unknown>;
}

export const generateBlogPath = ({
  section,
  article,
  queryParam,
}: BlogPathParams): string => {
  const blogPathWithLanguage = getBlogPathWithLanguage();
  const sectionName = normalizeStringForURL(section.name);
  const articleTitle = normalizeStringForURL(article.title);

  const path = `${blogPathWithLanguage}/${sectionName}/${articleTitle}`;
  if (queryParam) {
    return `${path}/?${QueryString.stringify(queryParam)}`;
  }
  return path;
};
