import { useRouter } from 'next/router';
import { Article } from 'node-zendesk/dist/types/clients/helpcenter/articles';
import { Section } from 'node-zendesk/dist/types/clients/helpcenter/sections';
import { FC, useEffect } from 'react';
import { getBlogPathWithLanguage } from 'src/utils/blog/language';
import { generateBlogPath } from 'src/utils/blog';
const DEPILATED_PAGE_REDIRECT_MAP: Record<string, string> = {
  '/home/ccpa.html': '/home/en-US/california-privacy-statement.html'
};
type PageProps = {
  blogSections: Section[];
  blogArticles: Article[];
};
const NotFoundView: FC<PageProps> = ({
  blogSections,
  blogArticles
}) => {
  const router = useRouter();
  useEffect(() => {
    /**
     * router 대신 window.location.pathname을 사용하는 이유
     * 404 페이지는 cloud front 설정에 의해 보여지는데 이때, 서버에서 redirect 시키는 방식이 아닌
     * URL을 유지한 채, html만 404 페이지의 것을 내려주는 방식을 사용함.
     *
     * 때문에, 실제 URL은 동적으로 달라지지만 next router는 항상 /404/ 인것으로 인지하게됨.
     */
    const {
      pathname
    } = window.location;

    /**
     * 공식적으로 번역을 지원하지 않는 언어로 접근 시도해서 404 떴을 때 fallback 개념으로 활용
     */
    if (pathname.startsWith('/blog')) {
      /**
       * 구버전 블로그 경로는
       * /blog/{languageInfo}/{sectionId}/{articleId}-{articleTitle} 형태임
       * ex) /blog/en-us/28817076525977/33319942934169-MakingFriendsOnlineHeresWhatToTalkAbout
       *
       * 구버전 블로그 경로에서 sectionId와 articleId를 추출하여 새로운 경로로 이동
       * 새로운 경로는 /blog/{languageInfo}/{sectionName}/{articleTitle} 형태임
       */
      const sectionId: string | undefined = pathname.split('/')[3];
      const articleId: string | undefined = pathname.split('/')[4]?.split('-')?.[0];
      const section = blogSections.find(({
        id
      }) => id === Number(sectionId));
      const article = blogArticles.find(({
        id
      }) => id === Number(articleId));

      // 신버전으로 변환 가능한 구버전 블로그 URL인 경우, 신버전 블로그 URL로 이동
      if (section && article) {
        const blogPath = generateBlogPath({
          section,
          article
        });
        router.replace(blogPath);
        return;
      }
      router.replace(getBlogPathWithLanguage());
      return;
    }

    /**
     * 페이지의 경로가 변경되었을 때, 새로운 경로로 이동
     */
    const depilatedRedirectPath = DEPILATED_PAGE_REDIRECT_MAP[pathname];
    if (depilatedRedirectPath) {
      router.replace(depilatedRedirectPath);
      return;
    }
    router.replace('/');
  }, [router]);
  return null;
};
export default NotFoundView;