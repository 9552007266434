import { getZendeskLanguage } from 'src/utils/zendesk';

//추후 번역 추가 가능
export const BLOG_SUPPORTED_LANGUAGES = [
  // 'ar',
  // 'pt-br',
  // 'nl',
  'en-us',
  // 'fr',
  // 'de',
  // 'hi',
  // 'hu',
  // 'id',
  // 'it',
  // 'ja',
  // 'ko',
  // 'ru',
  // 'zh-cn',
  // 'es',
  // 'sv',
  // 'th',
  // 'zh-tw',
  // 'tr',
  // 'vi',
];

const getBlogLanguage = () => {
  const BLOG_FALLBACK_LANGUAGE = 'en-us';

  const pathSegments = window.location.pathname.split('/');

  if (
    BLOG_SUPPORTED_LANGUAGES.includes(pathSegments?.[2]) &&
    pathSegments[1] === 'blog'
  ) {
    return `${pathSegments[2]}`;
  }
  if (BLOG_SUPPORTED_LANGUAGES.includes(getZendeskLanguage())) {
    return getZendeskLanguage();
  }
  return BLOG_FALLBACK_LANGUAGE;
};

export const getBlogPathWithLanguage = () => {
  const language = getBlogLanguage();
  return `/blog/${language}`;
};
