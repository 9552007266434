import type { ComponentProps } from 'react';
import { PageMetas } from 'src/types/meta';
import { blogZendeskClient } from 'src/utils/blog/zendeskApi';
import NotFoundView from 'src/views/NotFound';
export async function getStaticProps() {
  const blogSections = await blogZendeskClient.helpcenter.sections.list();
  const blogArticles = await blogZendeskClient.helpcenter.articles.list();
  return {
    props: {
      blogSections,
      blogArticles,
      noindex: true
    } as ComponentProps<typeof NotFoundView> & PageMetas
  };
}
export default NotFoundView;